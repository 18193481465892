import IHeaderLink from 'Models/Headers/HeaderLinkModel.interface';
import { styled } from 'Theme/stitches.config';
import DesktopMenuItem from './Components/MenuItem/DesktopMenuItem';
import InteractableContentLink from 'Shared/Components/Links/InteractableContentLink';
import {
  animateUnderlineActive,
  animateUnderlineNotActive,
} from 'Shared/Common/animateUnderline';
import ContentContainer from 'DesignComponents/Molecules/ContentContainer/ContentContainer';
import sizes from 'Theme/Settings/sizes';
import HeaderLinkModel from 'Models/Headers/HeaderLinkModel.interface';

type PropTypes = {
  currentItem: IHeaderLink;
  onCloseMenu: () => void;
};

function DesktopNavigationMenu({ currentItem, onCloseMenu }: PropTypes) {
  const hasSecondLevelHeading =
    !!currentItem?.heading || !!(currentItem?.text && currentItem?.href);

  const hasOneSubCategory = currentItem?.subLinks.length === 1;

  return (
    <Wrapper className="dropDownNavigationMenu">
      <ContentContainer
        width="content"
        css={{
          maxH: sizes.mainDesktopMenuMaxHeight,
          overflowY: 'auto',
        }}
      >
        {currentItem?.subLinks.length !== 0 && (
          <>
            {hasSecondLevelHeading && (
              <NavItemWrapper>
                {currentItem?.heading ? (
                  currentItem.heading
                ) : (
                  <InteractableContentLink href={currentItem?.href}>
                    {currentItem?.text}
                  </InteractableContentLink>
                )}
              </NavItemWrapper>
            )}

            <List
              hasMultipleCols={
                (currentItem?.subLinks?.length ?? 0) > 1 &&
                !!(
                  currentItem?.subLinks[0]?.heading ||
                  currentItem?.subLinks[0]?.text
                ) &&
                !!currentItem?.subLinks[0]?.subLinks?.length
              }
            >
              {currentItem?.subLinks?.map(
                (subLink: HeaderLinkModel, index: number) => (
                  <Li key={index}>
                    <DesktopMenuItem
                      item={subLink}
                      hasOneSubCategory={hasOneSubCategory}
                      onCloseMenu={onCloseMenu}
                    />
                  </Li>
                )
              )}
            </List>
          </>
        )}
      </ContentContainer>
    </Wrapper>
  );
}

export default DesktopNavigationMenu;

const Wrapper = styled('div', {
  backgroundColor: '$surface',
  overflowY: 'auto',
  w: '100%',
  maxW: '100%',
  overflowX: 'hidden',
  borderStyle: 'solid',
  borderBottomWidth: '$borders$default',
  borderColor: '$decorationEmphasized',
  whiteSpace: 'normal',
  '& a': {
    display: 'inline-block',
    verticalAlign: 'top',
  },
});

const NavItemWrapper = styled('div', {
  display: 'inline-block',
  fontSize: '$fontSize500',
  fontFamily: 'fontSemiBold',

  pt: '$s625',
  pb: '$s90',
  '& a': {
    ...animateUnderlineNotActive,
  },
  '&:hover': {
    '& a': {
      ...animateUnderlineActive,
    },
  },
});

const List = styled('ul', {
  marginBottom: '$s150',
  variants: {
    hasMultipleCols: {
      true: {
        display: 'flex',
        flexWrap: 'wrap',
        g: '$s250',
        '& > li': {
          flexBasis: '185px',
        },
      },
      false: {
        columnCount: 3,
        columnGap: '$s250',
        '@bpMin860': {
          columnCount: 5,
        },
        '@bpMin1281': {
          columnCount: 6,
        },
      },
    },
  },
});

const Li = styled('li');
