import ContentContainer from 'DesignComponents/Molecules/ContentContainer/ContentContainer';
import DesktopQuickSearch from 'DesignComponents/Organisms/QuickSearch/DesktopQuickSearch/DesktopQuickSearch';
import { styled } from 'Theme/stitches.config';
import { useHeaderData } from '../../Header';
import InteractableContentLink from 'Shared/Components/Links/InteractableContentLink';
import {
  animateUnderlineActive,
  animateUnderlineNotActive,
} from 'Shared/Common/animateUnderline';
import SiteLogo from 'DesignSystem/Icons/Icons/SiteLogo';

type PropTypes = {
  searchIsOpen: boolean;
  setSearchIsOpen: (value: boolean) => void;
  menuRef: React.RefObject<HTMLDivElement>;
};

const HeaderTop = ({ searchIsOpen, setSearchIsOpen, menuRef }: PropTypes) => {
  const { topLinks, siteLogotype } = useHeaderData();

  return (
    <Wrapper>
      <ContentContainer>
        <Container>
          <Flex>
            {siteLogotype?.src && (
              <InteractableContentLink href={`/`}>
                <SiteLogo src={siteLogotype.src} />
              </InteractableContentLink>
            )}
          </Flex>
          <DesktopQuickSearch
            searchIsOpen={searchIsOpen}
            setSearchIsOpen={setSearchIsOpen}
            menuRef={menuRef}
          />
          <Right>
            {topLinks.map((topLink, index: number) => {
              return (
                <InteractableContentLink key={index} href={topLink.href}>
                  {topLink.text}
                </InteractableContentLink>
              );
            })}
          </Right>
        </Container>
      </ContentContainer>
    </Wrapper>
  );
};

const Wrapper = styled('div', {
  position: 'relative',
  zIndex: '$Header',
  height: '72px',
  display: 'flex',
  alignItems: 'center',
  borderBottom: '1px solid $staticBorderSubtle',
});

const Flex = styled('div', {
  display: 'flex',
  alignItems: 'center',
});

const Container = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '$s150',
});

const Right = styled('div', {
  color: '$textPrimary',
  display: 'flex',
  alignItems: 'center',
  gap: '$s200',
  justifyContent: 'end',
  '& a': {
    mb: 0,
    whiteSpace: 'nowrap',
    fontSize: '$fontSize75',
    fontFamily: 'fontMedium',
    lineHeight: '$lh24',
    fontWeight: '$fontWeightRegular',
    wordSpacing: 'unset',
    color: '$onSurface',
    ls: 'unset',
    ...animateUnderlineNotActive,
    '&:hover': {
      ...animateUnderlineActive,
    },
  },
});

export default HeaderTop;
