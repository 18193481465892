import { styled } from 'Theme/stitches.config';
import IHeaderLink from 'Models/Headers/HeaderLinkModel.interface';
import {
  animateUnderlineActive,
  animateUnderlineNotActive,
} from 'Shared/Common/animateUnderline';
import InteractableContentLink from 'Shared/Components/Links/InteractableContentLink';
import DesktopNavigationMenu from 'DesignComponents/Organisms/Menu/DesktopMenu/DesktopNavigationMenu';

type PropTypes = {
  item: IHeaderLink;
  isActive: boolean;
  onClick: () => void;
  onCloseMenu: () => void;
};

function HeaderLink({ item, isActive, onClick, onCloseMenu }: PropTypes) {
  const isActiveItem = isActive || item.active;

  const renderText = (item: IHeaderLink, isActive: boolean) => {
    return (
      <Text selected={isActive}>
        {item?.dropdownHeading ??
          item?.dropdownLink?.text ??
          item?.heading ??
          item?.text}
      </Text>
    );
  };

  return (
    <>
      {item.subLinks.length > 0 ? (
        <ButtonWrapper
          onClick={onClick}
          aria-haspopup={item.subLinks.length > 0}
          aria-expanded={isActive}
        >
          {renderText(item, isActiveItem)}
        </ButtonWrapper>
      ) : (
        <InteractableContentLink
          onClick={onCloseMenu}
          href={item?.dropdownLink?.href ?? item.href}
        >
          {renderText(item, isActiveItem)}
        </InteractableContentLink>
      )}

      {item.subLinks && item.subLinks.length > 0 && (
        <DesktopMenuWrapper isOpen={isActive}>
          <DesktopNavigationMenu currentItem={item} onCloseMenu={onCloseMenu} />
        </DesktopMenuWrapper>
      )}
    </>
  );
}

const ButtonWrapper = styled('button', {
  display: 'flex',
  alignItems: 'center',
  marginLeft: 2,
  my: 'auto',
  color: '$textPrimary',
  fontFamily: 'fontMedium',
  fontSize: '$fontSize75',
  lh: '$lh171',
  cursor: 'pointer',

  position: 'relative',
});

const Text = styled('span', {
  color: '$onSurface',
  fontFamily: 'fontMedium',
  fontSize: '$fontSize75',
  lineHeight: '$lh24',
  fontWeight: '$fontWeightRegular',
  display: 'inline-block',
  position: 'relative',
  cursor: 'pointer',
  ...animateUnderlineNotActive,
  '&:hover': {
    ...animateUnderlineActive,
  },
  variants: {
    selected: {
      true: {
        ...animateUnderlineActive,
      },
    },
  },
});

const DesktopMenuWrapper = styled('div', {
  alignItems: 'center',
  alignContent: 'space-around',
  display: 'flex',
  left: '0',
  top: '100%',
  position: 'absolute',
  width: '100%',
  justifyContent: 'space-around',
  backgroundColor: '$onSurface',
  borderBottomWidth: '$bordersdefault',
  borderColor: '$decorationEmphasized',
  zIndex: '$zIndicesHeader',
  variants: {
    isOpen: {
      true: {
        visibility: 'visible',
      },
      false: {
        visibility: 'hidden',
      },
    },
  },
});

export default HeaderLink;
