import { styled } from 'Theme/stitches.config';
import InteractableContentLink from 'Shared/Components/Links/InteractableContentLink';
import IHeaderLink from 'Models/Headers/HeaderLinkModel.interface';
import {
  animateUnderlineActive,
  animateUnderlineNotActive,
} from 'Shared/Common/animateUnderline';

type PropTypes = {
  item: IHeaderLink;
  subLinkLevel?: 2 | 3;
  hasOneSubCategory?: boolean;
  onCloseMenu: () => void;
};

function NavigationLink({
  item,
  subLinkLevel,
  hasOneSubCategory,
  onCloseMenu,
}: PropTypes) {
  const { heading, href, text } = item || {};

  return (
    <ListItem subLinkLevel={subLinkLevel} onClick={onCloseMenu}>
      {heading ? (
        <HeadingWrapper hasOneSubCategory={hasOneSubCategory}>
          {heading}
        </HeadingWrapper>
      ) : (
        <InteractableContentLink href={href}>
          {subLinkLevel === 3 ? (
            <Text selected={item.active}>{text}</Text>
          ) : (
            text
          )}
        </InteractableContentLink>
      )}
    </ListItem>
  );
}

const HeadingWrapper = styled('div', {
  variants: {
    hasOneSubCategory: {
      true: {
        fontSize: '$fontSize500',
        fontFamily: 'fontSemiBold',
        lineHeight: '$lh28',
      },
    },
  },
});

const ListItem = styled('li', {
  listStyle: 'none',
  p: '$s625',
  pl: 0,
  lineHeight: '$lh20',
  variants: {
    subLinkLevel: {
      2: {
        fontSize: '$fontSize200',
        fontFamily: 'fontSemiBold',
      },
      3: {
        fontSize: '$fontSize75',
      },
    },
  },
});

const Text = styled('span', {
  color: '$onSurface',
  ...animateUnderlineNotActive,
  '&:hover': {
    ...animateUnderlineActive,
  },
  variants: {
    selected: {
      true: {
        ...animateUnderlineActive,
      },
    },
  },
});

export default NavigationLink;
