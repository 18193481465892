import { useState, useEffect } from 'react';
import usePreventBackgroundScroll from 'Shared/Hooks/usePreventBackgroundScroll';
import { styled } from 'Theme/stitches.config';

type PropTypes = {
  isVisible: boolean;
};

const NavigationOverlay = ({ isVisible }: PropTypes) => {
  usePreventBackgroundScroll();

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (isVisible) {
      setVisible(true);
    } else {
      const timeout = setTimeout(() => setVisible(false), 250);
      return () => clearTimeout(timeout);
    }
  }, [isVisible]);

  return <>{isVisible && <MenuOverlay visible={visible} />}</>;
};

export default NavigationOverlay;

const MenuOverlay = styled('div', {
  w: '100vw',
  h: '100vh',
  position: 'fixed',
  l: 0,
  t: 0,
  opacity: '0',
  visibility: 'hidden',
  zIndex: '$OverlayMegaMenu',
  transition: 'opacity 0.15s ease-in-out',
  backgroundColor: '$surface',
  variants: {
    visible: {
      true: {
        opacity: '.7',
        visibility: 'visible',
      },
    },
  },
});
