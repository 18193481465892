import { useState, useRef } from 'react';
import { styled } from 'Theme/stitches.config';

import HeaderTop from './HeaderTop/HeaderTop';
import HeaderBottom from './HeaderBottom/HeaderBottom';

import NavigationOverlay from '../../Menu/NavigationOverlay/NavigationOverlay';
import { useUiState } from 'Shared/Providers/UiState/UiStateProvider';
import { mapModalView } from 'DesignComponents/Organisms/Modal/ModalContentViews';
import MiniCartContent from 'Commerce/Cart/MiniCart/MiniCartContent';
import { ModalFooterLayoutOption } from 'Enums/ModalFooterLayout.enum';

import { mapModalFooterView } from 'DesignComponents/Organisms/Modal/ModalFooterViews';
import MiniCartModalFooter from 'Commerce/Cart/MiniCart/MiniCartModalFooter';
import { useHeaderData } from '../Header';
import TopBanner from 'DesignComponents/Organisms/TopBanner/TopBanner';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { SrOnlyStyle } from 'DesignSystem/Accessibility/Utils';

function DesktopHeader() {
  const {
    cartLabels: { cartHeader },
    commonLabels: { skipToContent },
  } = useTranslationData();

  const { modalState, toggleModal } = useUiState();

  const { uniqueSellingPoints } = useHeaderData();

  const menuRef = useRef<HTMLDivElement>(null);

  const [searchIsOpen, setSearchIsOpen] = useState(false);

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const onOpenMiniCart = (e: React.MouseEvent<HTMLButtonElement>) => {
    toggleModal(
      !modalState.display,
      'MiniCart',
      cartHeader,
      {
        component: mapModalView<typeof MiniCartContent>({
          type: 'MiniCart',
          props: {},
        }),
      },
      mapModalFooterView<typeof MiniCartModalFooter>({
        type: 'MiniCartModalFooter',
        props: {
          displayModal: !modalState.display,
          modalFooterContent: {
            footerLayout: ModalFooterLayoutOption.STACKED,
          },
        },
      }),
      e.currentTarget as HTMLElement
    );
  };

  return (
    <>
      <Placeholder />
      <HeaderSection ref={menuRef} id="site-header">
        <SkipLinkWrapper>
          <SkipLink href="#root-content">{skipToContent}</SkipLink>
        </SkipLinkWrapper>
        {uniqueSellingPoints.length > 0 && (
          <TopBanner uspItems={uniqueSellingPoints} />
        )}
        <HeaderTop
          searchIsOpen={searchIsOpen}
          setSearchIsOpen={setSearchIsOpen}
          menuRef={menuRef}
        />
        <HeaderBottom
          openMiniCart={onOpenMiniCart}
          menuRef={menuRef}
          onOpenMenu={(value) => setMenuIsOpen(value)}
        />
      </HeaderSection>

      {(menuIsOpen || searchIsOpen) && (
        <NavigationOverlay isVisible={menuIsOpen || searchIsOpen} />
      )}
    </>
  );
}

const HeaderSection = styled('div', {
  backgroundColor: '$surface',
  color: '$siteHeaderLinkColor',
  t: 0,
  position: 'relative',
  zIndex: '$zIndices$Header',
  width: '100%',
});

const Placeholder = styled('div', {
  w: '100%',
  minH: '$sizes$desktopHeaderHeight',
  position: 'absolute',
  background: 'transparent',
});

const SkipLinkWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '$surfaceVariant',
  ':focus-within': {
    height: '40px',
  },
});

const SkipLink = styled('a', {
  ...SrOnlyStyle,
  color: 'blue',
  '&:focus': {
    position: 'relative',
    height: 'auto',
    width: 'auto',
    padding: '10px',
    margin: '5px',
    boxShadow: 'none',
  },
});

export default DesktopHeader;
